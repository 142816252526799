<template>
  <div class="layout scrollbar">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
// 引入组件
import TopNav from "./TopNav.vue";
import FooterInfo from "./FooterInfo.vue";
import imChat from "@/components/imChat/imChat.vue";

export default {
  components: {
    TopNav,
    FooterInfo,
    imChat,
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 550px) {
  .layout {
    width: 100%;
    height: 100%;
    min-width: 1400px;
    min-height: 100%;
    overflow-y: scroll;
    overflow-x: scroll;
    .output {
      background-color: #f9f9f9;
    }
  }
}

@media screen and (min-width: 200px) and (max-width: 500px) {
  .layout {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    .output {
      background-color: #f9f9f9;
    }
  }
}
</style>
