<template>
  <div class="top_nav">
    <div class="container">
      <div
        class="logo_wrapper cursorP"
        @click.stop="jumpToPage({ path: 'home', name: '首页' })"
      >
        <img
          class="icon_logo"
          src="https://www.zizhihelp.com/upload/static/web/web_nav_logo.png"
          alt=""
        />
        <!-- <div class="name_wrapper">
          <div class="name">有信资质帮</div>
          <div class="desc">建筑业大数据服务平台</div>
        </div> -->
      </div>

      <div class="menu">
        <div
          class="menu_itme"
          v-for="(item, index) in menuList"
          :key="item.name"
          @click="jumpToPage(item, index)"
        >
          <div :class="['item mr5', index == activeMenu ? 'active' : '']">
            {{ item.name }}
          </div>
          <i v-if="item.children.length > 0" class="el-icon-arrow-down"></i>

          <div class="menu_child" v-if="item.children.length > 0">
            <div
              class="child_item"
              v-for="child in item.children"
              :key="child.name"
              @click.stop="jumpToPage(child, index)"
            >
              <img class="icon" :src="child.iconUrl" alt="" />
              <div class="text">{{ child.name }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="vip cursorP">
        <img
          class="icon_vip"
          src="https://www.zizhihelp.com/upload/static/web/huiyuan.png"
          alt=""
        />
        <span>会员服务</span>
      </div>

      <img
        class="icon_phone cursorP"
        src="https://www.zizhihelp.com/upload/static/web/shouji.png"
        alt=""
      />

      <div class="feature flex alc cursorP">
        <span class="mr5">功能导航</span>
        <i class="el-icon-caret-bottom"></i>
      </div> -->

      <div class="user ml20 cursorP">
        <div class="nickname" @click="login">
          {{ userInfo.nickname }}
        </div>

        <div class="user_children" v-if="userInfo.id">
          <div class="user_children_item" @click="toPersonCenter">个人中心</div>
          <div class="user_children_item" @click="logout">退出登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* 头部导航 */
import local from "@/utils/local";

export default {
  // 数据
  data() {
    return {
      menuList: [
        { name: "首页", path: "home", children: [] },
        {
          name: "分项查询",

          path: "itemizedQuery",
          children: [
            {
              name: "查企业",
              path: "itemizedQuery",
              iconUrl: "https://www.zizhihelp.com/upload/static/查企业.png",
            },
            {
              name: "查业绩",
              path: "itemizedQuery",
              iconUrl: "https://www.zizhihelp.com/upload/static/查业绩.png",
            },
            {
              name: "查资质",
              path: "itemizedQuery",
              iconUrl: "https://www.zizhihelp.com/upload/static/查资质.png",
            },
            {
              name: "项目经理",
              path: "itemizedQuery",
              iconUrl: "https://www.zizhihelp.com/upload/static/项目经理.png",
            },
            {
              name: "技术负责人",
              path: "itemizedQuery",
              iconUrl: "https://www.zizhihelp.com/upload/static/技术负责人.png",
            },
            {
              name: "查人员",
              path: "itemizedQuery",
              iconUrl: "https://www.zizhihelp.com/upload/static/查人员.png",
            },
            {
              name: "查招标",
              path: "itemizedQuery",
              iconUrl: "https://www.zizhihelp.com/upload/static/查招标.png",
            },
            {
              name: "查荣誉",
              path: "itemizedQuery",
              iconUrl: "https://www.zizhihelp.com/upload/static/rongyu.png",
            },
            {
              name: "查诚信",
              path: "itemizedQuery",
              iconUrl: "https://www.zizhihelp.com/upload/static/chengxin.png",
            },
            {
              name: "查评价",
              path: "itemizedQuery",
              iconUrl: "https://www.zizhihelp.com/upload/static/pingjia.png",
            },
          ],
        },
        { name: "组合查询", path: "combinedQuery", children: [] },
        { name: "四库专查", path: "fourthLibrary", children: [] },
        { name: "水利专查", path: "waterConservancy", children: [] },
        { name: "公路专查", path: "highroad", children: [] },
        { name: "项目拟建", path: "projectProposal", children: [] },
        // { name: "研究院", path: "", children: [] },
      ],

      activeMenu: null, // 当前激活的菜单项索引

      userInfo: {}, // 用户信息
    };
  },
  // 计算属性
  computed: {
    // curActive() {
    //   return this.$route.path;
    // },
  },

  watch: {
    $route: {
      handler(newVal) {
        if (newVal.meta.index) {
          this.activeMenu = newVal.meta.index;
        } else if (newVal.meta.index === 0) {
          this.activeMenu = 0;
        } else {
          this.activeMenu = null;
        }
      },
      immediate: true,
      deep: true,
    },
  },

  // 方法
  methods: {
    /* 操作 */
    // 页面跳转
    jumpToPage(item, index) {
      if (index !== undefined) {
        this.activeMenu = index;
      }
      const path = item.path;
      if (path) {
        this.$router.push({ name: path, params: { name: item.name } });
      }
    },
    // 个人中心
    toPersonCenter() {
      alert("暂无个人中心页面");
    },
    // 退出登录
    logout() {
      this.$confirm("确定退出登录？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          local.clear();
          this.$router.push("/login");
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消",
          // });
        });
    },
    // 登录
    login() {
      this.$router.push("/login");
    },

    /* 初始化 */
    __init__() {
      this.userInfo = local.get("userInfo") || { nickname: "登录" };
    },
  },

  // 生命周期-实例创建完成
  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.top_nav {
  // background-color: #d7edfb;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 2001;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .container {
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 13px 0px;
    font-size: 16px;
    color: #333;

    .logo_wrapper {
      display: flex;
      align-items: center;
      .icon_logo {
        // width: 34px;
        height: 50px;
        // margin-right: 6px;
      }

      .name_wrapper {
        .name {
          font-size: 16px;
          line-height: 22px;
          font-weight: 500;
          color: #1d89e3;
        }

        .desc {
          font-weight: 400;
          font-size: 8px;
          color: #666666;
          line-height: 11px;
        }
      }
    }

    .menu {
      flex: 1;
      display: flex;
      align-items: center;

      .menu_itme {
        // flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        line-height: 70px;
        padding: 0px 30px;

        .menu_child {
          position: absolute;
          top: 55px;
          left: 0px;
          z-index: 12;
          width: 360px;
          background-color: #fff;
          display: none;
          align-items: center;
          flex-wrap: wrap;
          border-radius: 10px;
          box-shadow: 0px 0px 4px 0px rgba(153, 153, 153, 0.5);

          .child_item {
            width: 33.333333%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;

            .icon {
              width: 40px;
              height: 40px;
              margin-bottom: 5px;
            }

            .text {
              line-height: 20px;
            }

            &:hover {
              background-color: #d7edfb;
              border-radius: 6px;
            }
          }
        }

        &:hover {
          .menu_child {
            display: flex;
          }
        }
      }

      .active {
        color: #1d89e3;
      }
    }

    .vip {
      display: flex;
      align-items: center;
      padding: 5px 10px;
      color: #5a2a00;
      background: linear-gradient(90deg, #ffe4a1 0%, #f3ca90 100%);
      border-radius: 8px;
      line-height: 22px;

      .icon_vip {
        width: 20px;
        height: 18px;
        margin-right: 5px;
      }
    }

    .icon_phone {
      width: 18px;
      height: 24px;
      margin: 0px 24px;
    }

    .feature {
      .el-icon-caret-bottom {
        color: #333;
      }
    }

    .user {
      position: relative;

      .nickname {
        line-height: 60px;
      }

      .user_children {
        display: none;
        position: absolute;
        top: 55px;
        left: -20px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 4px 0px rgba(153, 153, 153, 0.5);

        .user_children_item {
          display: flex;
          padding: 20px 30px;
          white-space: nowrap;
          cursor: pointer;
          font-size: 14px;
          border-bottom: 1px solid #ddd;

          &:last-child {
            border-bottom: none;
          }
          &:hover {
            background-color: #d7edfb;
            &:nth-child(1) {
              border-radius: 10px 10px 0px 0px;
            }
            &:nth-child(2) {
              border-radius: 0px 0px 10px 10px;
            }
          }
        }
      }

      &:hover {
        .user_children {
          display: block;
        }
      }
    }
  }
}
</style>
