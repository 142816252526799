// store.js
import Vue from 'vue'
import Vuex from 'vuex'
import local from '@/utils/local.js'

Vue.use(Vuex);
const bus = new Vue()


const store = new Vuex.Store({
  state: {
    socket: null,
    messages: []
  },
  mutations: {
    SET_SOCKET(state, socket) {
      state.socket = socket;
    },
    ADD_MESSAGE(state, message) {
      state.messages.push(message);
    }
  },
  actions: {
    // 连接WebSocket服务器
    connectWebSocket({ commit }, url) {
      // 创建WebSocket连接
      const socket = new WebSocket(url);
      socket.addEventListener('open', () => {
        commit('SET_SOCKET', socket);
        console.log('全局websocket连接成功')
      });
      // 添加消息事件监听器
      socket.addEventListener('message', (res) => {
        let temp = local.get('newMsg') || []
        temp.push(JSON.parse(res.data))
        local.set('newMsg', temp)
        bus.$emit('newMsg', temp);
        console.log('收到服务器内容：', temp)
      });

      // 添加错误和关闭事件监听器
      socket.addEventListener('error', (err) => {
        console.log('WebSocket连接失败：', err);

        bus.$emit('websocketErr');
      });
      socket.addEventListener('close', (err) => {
        console.log('WebSocket关闭连接：', err);

        bus.$emit('websocketErr');
      });
    },

    // 发送消息到WebSocket服务器
    senWebSocket(state, data) {
      state.state.socket.send(data)
    },
  }
});


export {
  store,
  bus
}
