<template>
  <div class="app">
    <!-- 出口 -->
    <keep-alive :include="include">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
import local from "@/utils/local.js";
export default {
  data() {
    return { include: ["home"] };
  },

  methods: {
    // 处理websocket事件
    websocketEvent() {
      // 监听websocket连接错误事件
      this.$bus.$on("websocketErr", () => {
        local.set("websocketErr", true);
      });

      // 创建websocket链接
      const userInfo = local.get("userInfo") || { id: null };
      if (!userInfo.id) return;
      this.$store.dispatch(
        "connectWebSocket",
        `wss://www.zizhihelp.com/api/websocket/${userInfo.id}`
      );
      /* 每隔1分钟检查一次是否有websocket连接，若没有则重连websocket */
      setInterval(() => {
        const userInfo = local.get("userInfo") || { id: null };
        const websocketErr = local.get("websocketErr") || false;
        console.log("websocketErr>>>>", websocketErr);
        if (!userInfo.id) return;
        if (websocketErr) {
          this.$store.dispatch(
            "connectWebSocket",
            `wss://www.zizhihelp.com/api/websocket/${userInfo.id}`
          );
          local.set("websocketErr", false);
        }
      }, 60000);
    },
  },

  mounted() {
    this.websocketEvent();
  },
};
</script>

<style lang="less" scoped>
.app {
  width: 100%;
  height: 100%;

  /deep/.scrollbar {
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #e1e2e5;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background: #ffffff;
    }
  }

  /deep/.el-table__body-wrapper {
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #e1e2e5;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background: #ffffff;
    }
  }
}
</style>

<style lang="less"></style>
